import Vue from "vue";
import Toast from "vue-toastification";
import Vuelidate from "vuelidate";
import VueTippy from "vue-tippy";
import "tippy.js/themes/light.css";
import "tippy.js/themes/light-border.css";
import "./fontawesome";
import "./vue-good-table";
import "./highcharts";
import "./apexcharts";
import "./vuevirtualscroll"
import VModal from "vue-js-modal";
// modules Css
import "vue-toastification/dist/index.css";

const options = {
    // You can set your default options here
    position: "top-right",
    newestOnTop: true,
    maxToasts: 10,
    timeout: 4000,
    pauseOnFocusLoss: false,
    // icon: false
    // transition: "Vue-Toastification__bounce",
    hideProgressBar: false,
    transition: {
        enter: "fade-enter-active",
        leave: "Vue-Toastification__bounce-leave-active",
        move: "fade-move",
    },
};

Vue.use(VModal, { dialog: true });
Vue.use(Toast, options);
Vue.use(Vuelidate);
Vue.use(VueTippy, {
    directive: "tippy",
});
